import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function ChevronRightSmallIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 9 16'}
            >
                <path d={'M6.97 8.5L0.5 15.188L1.772 16.5L9.5 8.5L1.772 0.499999L0.500001 1.812L6.97 8.5Z'} />
            </SVGIcon>
        </IconWrapper>
    );
}
