import React from 'react';

import debounce from 'lodash.debounce';

import { useAtom, useSetAtom } from '@/core/features/store/atom-store';
import { mapScreenSizeAtom } from '@/features/map/map-container/map-state';
import { showMainContentAtom } from '@/features/page-layouts/desktop/desktop-map-search-layout';

export type ComponentSize = { height: number; width: number };

export default function useMapSplitscreenSize(ref: React.RefObject<HTMLElement>) {
    const [showMainContent] = useAtom(showMainContentAtom);
    const setMapScreenSize = useSetAtom(mapScreenSizeAtom);

    React.useEffect(() => {
        const handleResize = debounce(
            () => {
                setMapScreenSize({
                    height: ref.current?.clientHeight || 0,
                    width: ref.current?.clientWidth || 0,
                });
            },
            300,
            { leading: true },
        );

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, [ref, setMapScreenSize, showMainContent]);
}
